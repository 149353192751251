import React from "react";

// Admin Imports
// import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import QuestionTable from "views/admin/question";


// Auth Imports
import SignIn from "views/auth/SignIn";
import QbankTable from "views/admin/qbank";
import TestTable from "views/admin/tests";
// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdQuestionAnswer,
  MdChat,
  MdQuestionMark,
} from "react-icons/md";
import UserTable from "views/admin/Users";
import ReportTable from "views/admin/Report";

const routes = [
  // {
  //   name: "Main Dashboard",
  //   layout: "/admin",
  //   path: "default",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <MainDashboard />,
  // },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  
  {
    name: "QBanks",
    layout: "/admin",
    path: "qbank",
    icon: <MdQuestionAnswer className="h-6 w-6" />,
    component: <QbankTable />,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    icon: <MdPerson className="h-6 w-6" />,
    component: <UserTable />,
  },
  
  {
    name: "Feedback",
    layout: "/admin",
    path: "feedback",
    icon: <MdChat className="h-6 w-6" />,
    component: <ReportTable />,
  },
  // {
  //   name: "Questions",
  //   layout: "/admin",
  //   path: "question",
  //   icon: <MdQuestionMark className="h-6 w-6" />,
  //   component: <QuestionTable />,
  // },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
];
export default routes;
