import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdOutlineError, MdOutlineRefresh, MdOutlineVerified } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { BASE_URL } from "variables/Endpoints";
import { Empty, Tooltip, notification } from 'antd';
import { useNavigate } from "react-router-dom";
import { Spin } from 'antd';


const ComplexTable = (props) => {
  const { columnsData } = props;

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const [isLoader, setisLoader] = useState(true);


  



  let tokenMe = localStorage.getItem("accessToken");


  const fetchData = async () => {
    var data = '';
    // setisLoader(true);
    var config = {
      method: 'get',
      url: `${BASE_URL}admin/ticket`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setTableData(response?.data?.data);
        setisLoader(false)
        // console.log(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);

        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          api['error']({
            message: error?.response?.data?.error,
            key: 'Success Message',
          });
        }
      });
  };
  




  const ActivateUser = async (userid) => {
    var data = {
      "response":"Corrected",
      "id":userid
  };

    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/ticket`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        fetchData();
        api['success']({
          message: response?.data?.data == 1 ? "Status Updated Successfully" : response?.data?.data,
          key: 'Success Message',
        });



      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          api['error']({
            message: error?.response?.data?.error,
            key: 'Success Message',
          });
        }
      });
  };







  useEffect(() => {
    fetchData();
  }, []);




  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows, // Use 'rows' instead of 'page' to access all rows
    prepareRow,
  } = tableInstance;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
      {contextHolder}

      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Feedback Table
        </div>
        <button
          onClick={() => fetchData()}
          className={`flex items-center text-xl hover:cursor-pointer ${false
              ? "bg-none text-white hover:bg-none active:bg-none"
              : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          <MdOutlineRefresh className="h-6 w-6" />
        </button>


      </div>



      {isLoader ? (
        <div className="flex items-center justify-center h-full">
          <Spin />
        </div>
      ) : (



        <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {/* <th
                  
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                > */}
                  {/* <p className="text-xs tracking-wide text-gray-600">
                    ID
                  </p> */}
                  {/* </th> */}
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      className={`border-b border-gray-200 pb-[10px] text-start dark:!border-navy-700 ${column.Header === "USERNAME" ? "" : "pr-35"
                        }`}
                    >
                      <p className="text-xs tracking-wide text-gray-600">
                        {column.render("Header")}
                      </p>
                    </th>
                  ))}


                  {/* <th
                  
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  > */}
                  {/* <p className="text-xs tracking-wide text-gray-600">
                      ACTIONS
                    </p> */}
                  {/* </th> */}
                </tr>
              ))}
            </thead>
            
           {rows && rows.length !== 0 ? 
           <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {

                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>


                    {row?.cells.map((cell, index) => {
                      let data = "";
                      // console.log(cell.value);
                      if (cell.column.Header === "ID") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {row?.index + 1}
                          </p>
                        );
                      }
                      else if (cell.column.Header === "FIRST NAME") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell?.value ? cell?.value : "First Name not found"}
                          </p>
                        );
                      }
                      else if (cell.column.Header === "EMAIL") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell?.value ? cell?.value : "Email not found"}
                          </p>
                        );
                      }
                      else if (cell.column.Header === "QBANK NAME") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell?.value ? cell?.value : "Qbank not found"}
                          </p>
                        );
                      }
                      else if (cell.column.Header === "TEST NAME") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell?.value ? cell?.value : "Test not found"}
                          </p>
                        );
                      }
                    
                      else if (cell.column.Header === "DESCRIPTION") {
                        data = (

                          <Tooltip title={cell.value}>

                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value === null || cell.value === "" ? "No Description Found" : cell.value.length > 10 ? cell.value.slice(0,10) + "..." : cell.value}
                          </p>
                          </Tooltip>
                        );
                       
                      } 
                      else if (cell.column.Header === "QUESTION") {
                        data = (

                          <Tooltip title={cell.value}>

                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value === null || cell.value === "" ? "No Question Found" : cell.value.length > 10 ? cell.value.slice(0,10) + "..." : cell.value}
                          </p>
                          </Tooltip>
                        );
                       
                      } 
                      else if (cell.column.Header === "STATUS") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              { cell?.value === "CLOSE" ? (
                                <>
                                      <MdCheckCircle style={{display:"inline-block"}} className="text-green-500" /> <span style={{fontSize:13, fontWeight:600}}>{cell.value}</span>
                                </>
                                    ) : cell.value === "OPEN" ? (
                                      <>
                                      <MdOutlineError style={{display:"inline-block"}} className="text-orange-500" /> <span style={{fontSize:13, fontWeight:600}}>{cell.value}</span>
                                      </>
                                      ): <>
                                      <MdCheckCircle style={{display:"inline-block"}} className="text-green-500" /> <span style={{fontSize:13, fontWeight:600}}>{cell.value}</span></>}
                            </div>
                           
                          </div>
                        );
                      } else if (cell.column.Header === "RESPONSE") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value !== null ? cell.value : "Response not found"}
                          </p>
                        );
                      }else if (cell.column.Header === "ACTIONS") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white flex">


                            <Tooltip title="Corrected">

                              <button
                                onClick={() => ActivateUser(row?.original?.id)}
                                className={`flex items-center text-xl hover:cursor-pointer mr-3 ${false
                                    ? "bg-none text-white hover:bg-none active:bg-none"
                                    : "bg-green-500 p-2 text-white hover:bg-green-400 dark:bg-green-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                  } linear justify-center rounded-lg font-bold transition duration-200`}
                              >
                                <MdOutlineVerified className="h-6 w-6" />
                              </button>
                            </Tooltip>


                          </p>
                        );
                      }
                    


                      return (
                        <td
                          className="pt-[14px] pb-[18px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={index}
                        >
                          <div
                          >

                            {data}


                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>:
<tr>
  <td colSpan={5}>
  <br />
  <br />
  <Empty />
  <br />
  <br />

  </td>
</tr>
            }



          </table>
        </div>

      )}

    
    </Card>
  );
};

export default ComplexTable;
