import InputField from "components/fields/InputField";
import Logo from "../../assets/img/auth/logo.png";
import { useState } from "react";
import axios from 'axios';
import { BASE_URL } from "variables/Endpoints";
import { Spin, notification } from 'antd';
import { useNavigate } from "react-router-dom";
export default function SignIn() {
  const [loading, setLoading] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
let LoginFunction = () => {
  setLoading(true);
  var data = JSON.stringify({
    "email": adminEmail,
    "password": adminPassword
  });
  var config = {
    method: 'post',
    url: `${BASE_URL}user/login`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  axios(config)
  .then(function (response) {
    console.log(response?.data);
if(response?.data?.code === 200 && response?.data?.success === true){
  if(response?.data?.data?.user?.isActive === true){
    if(response?.data?.data?.user?.isBlocked === false){
      if(response?.data?.data?.user?.isVerified === true){
        setLoading(false);
        localStorage.setItem("accessToken", response?.data?.data?.accessToken);
        localStorage.setItem("refreshToken", response?.data?.data?.refreshToken);
        localStorage.setItem("userDetails", typeof(response?.data?.data?.user) === 'object' ? JSON.stringify(response?.data?.data?.user) : null);
        api['success']({
          message: 'Successfully Logged In!',
            key: 'SuccessMSG',
        });
        setTimeout(()=>{
          navigate("/admin")
        },2000)
      }else{
        api['error']({
          message: 'Your Account is not verified!',
            key: 'ErrorMessage',
        });
        setLoading(false);
      }
    }else{
      api['error']({
        message: 'Your Account is blocked!',
          key: 'ErrorMessage',
      });
      setLoading(false);
    }
  }else{
    api['error']({
      message: 'Your Account is not activated!',
        key: 'ErrorMessage',
    });
    setLoading(false);
  }
}
  })
  .catch(function (error) {
    setLoading(false);
    api['error']({
      message: error?.response?.data?.error,
        key: 'ErrorMessage',
    });
  });
}
  return (
    <Spin spinning={loading} delay={100}>
     <main className="mx-auto min-h-screen">
      <div className="relative  min-h-screen h-full w-full  !bg-gray-100 dark:!bg-gray-800"  >
        {/* <FixedPlugin /> */}
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-center pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full items-center justify-center">
    <div style={{maxWidth: '550px',borderRadius: '10px', maxHeight:"700px"}} className="mt-10 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center !bg-white dark:!bg-navy-900">
                    {contextHolder}
      {/* Sign in section */}
      <div  className="mt-[8vh] mb-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
      <img src={Logo} className="mx-auto" style={{width: '100px'}} />
        <h4 className="mb-2.5 text-4xl mt-6 font-bold text-navy-700 dark:text-white ">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600 ">
          Enter your email and password to sign in!
        </p>
      {/* Email */}
        <InputField
        onChange={(e) => setAdminEmail(e.target.value)}
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="Enter your email"
          id="email"
          type="text"
        />
        {/* Password */}
        <InputField
                onChange={(e) => setAdminPassword(e.target.value)}
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Enter your Password"
          id="password"
          type="password"
        />
        {/* Checkbox */}
        {/* <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
        </div> */}
        <button  onClick={()=>LoginFunction()} className="linear mt-5 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign In
        </button>
      </div>
    </div>
    </div>
            </div>
          </div>
        </main>
      </div>
    </main>
    </Spin>
  );
}
