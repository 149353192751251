import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError, MdOutlineRefresh, MdOutlineVerified } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import Progress from "components/progress";
import axios from "axios";
import { BASE_URL } from "variables/Endpoints";
import { Button, Modal, Select, Tooltip, notification } from 'antd';
import { CgUnblock, CgBlock, CgAssign } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { Spin } from 'antd';


const ComplexTable = (props) => {
  const { columnsData } = props;

  const navigate = useNavigate();

  const [tableData, setTableData] = useState([]);
  const [QbankData, setQbankData] = useState([]);
  const [selectTObject, setSelectTObject] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [qbankID, setqbankID] = useState("");
  const [isLoader, setisLoader] = useState(true);


  const openNotificationsucess = (placement, msg) => {
    api.success({
      message: msg,
      placement,
    });
  };

  const openNotificationError = (placement, msg) => {
    api.error({
      message: msg,
      placement,
    });
  };



  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalRemoveOpen, setisModalRemoveOpen] = useState(false);
  const showModal = (ids) => {
    setIsModalOpen(true);
    setUserId(ids);
  };

  const showModalRemove = (ids, qbankid) => {
    console.log(qbankid);
    setisModalRemoveOpen(true);
    setqbankID(qbankid);
    setUserId(ids);
  };

  const handleOkRemove = () => {
    setLoading(true);
    RemoveQbanks();

  };


  const handleOk = () => {
    setLoading(true);
    AssignQbanks();

  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleCancelRemove = () => {
    setisModalRemoveOpen(false);
  };

  const AssignQbanks = async () => {

    var data = {
      "userid": userId?.id,
      "qbankid": selectedValue
    };

    // console.log(selectedValue);
    var config = {
      method: 'post',
      url: `${BASE_URL}admin/qbank/user`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };



    // if(response?.data?.code === 200 && response?.data?.success === true){


    axios(config)
      .then(function (response) {

        openNotificationsucess('top', 'Successfully QBank Assigned');
        fetchData();

        setLoading(false);
        setIsModalOpen(false);
        setSelectedValue("")
      })
      .catch(function (error) {


        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          setLoading(false);
          setIsModalOpen(false);
          setSelectedValue("")

          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          setLoading(false);
          setIsModalOpen(false);
          setSelectedValue("")

          openNotificationError('top', error?.response?.data?.error);


        }
      });
  };



  const RemoveQbanks = async () => {

    var data = {
      "userid": userId?.id,
      "qbankid": qbankID
    };
    console.log(data);

    // console.log(selectedValue);
    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/qbank/user`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };



    // if(response?.data?.code === 200 && response?.data?.success === true){


    axios(config)
      .then(function (response) {

        openNotificationsucess('top', 'Successfully QBank Removed');
        fetchData();

        setLoading(false);
        setisModalRemoveOpen(false);
        fetchData();
        setSelectedValue("")
      })
      .catch(function (error) {


        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          setLoading(false);
          setisModalRemoveOpen(false);
          setSelectedValue("")

          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          setLoading(false);
          setisModalRemoveOpen(false);
          setSelectedValue("")

          openNotificationError('top', error?.response?.data?.error);


        }
      });
  };


  let tokenMe = localStorage.getItem("accessToken");


  const fetchData = async () => {
    var data = '';
    // setisLoader(true);
    var config = {
      method: 'get',
      url: `${BASE_URL}admin/users`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setTableData(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);

        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          api['error']({
            message: error?.response?.data?.error,
            key: 'Success Message',
          });
        }
      });
  };
  const fetchQBankData = async () => {
    var data = '';

    var config = {
      method: 'get',
      url: `${BASE_URL}admin/qbank`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setQbankData(response?.data?.data);

        const transformedData = response?.data?.data && (response?.data?.data.length !== 0) && response?.data?.data.map(item => ({
          value: item.id,
          label: item.name,
        }));

        setSelectTObject(transformedData)
        setisLoader(false);
      })
      .catch(function (error) {
        console.log(error);

        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          api['error']({
            message: error?.response?.data?.error,
            key: 'Success Message',
          });
        }
      });
  };



  const handleChangeSelect = (value) => {
    setSelectedValue(value)
  };


  const ActivateUser = async (userid) => {
    var data = '';

    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/users/active/${userid}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        fetchData();
        api['success']({
          message: response?.data?.data,
          key: 'Success Message',
        });



      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          api['error']({
            message: error?.response?.data?.error,
            key: 'Success Message',
          });
        }
      });
  };



  const BlockUser = async (userid) => {
    var data = '';

    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/users/block/${userid}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        fetchData();
        api['success']({
          message: response?.data?.data,
          key: 'Success Message',
        });
      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          api['error']({
            message: error?.response?.data?.error,
            key: 'Success Message',
          });
        }
      });

  };



  const UnBlockUser = async (userid) => {
    var data = '';

    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/users/unblock/${userid}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        fetchData();
        api['success']({
          message: response?.data?.data,
          key: 'Success Message',
        });
      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          api['error']({
            message: error?.response?.data?.error,
            key: 'Success Message',
          });
        }
      });

  };



  useEffect(() => {
    fetchData();
    fetchQBankData();
  }, []);




  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows, // Use 'rows' instead of 'rows' to access all rows
    prepareRow,
  } = tableInstance;


  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
      {contextHolder}

      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Users Table
        </div>
        <button
          onClick={() => fetchData()}
          className={`flex items-center text-xl hover:cursor-pointer ${false
              ? "bg-none text-white hover:bg-none active:bg-none"
              : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          <MdOutlineRefresh className="h-6 w-6" />
        </button>


      </div>



      {isLoader ? (
        <div className="flex items-center justify-center h-full">
          <Spin />
        </div>
      ) : (



        <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {/* <th
                  
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                > */}
                  {/* <p className="text-xs tracking-wide text-gray-600">
                    ID
                  </p> */}
                  {/* </th> */}
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      className={`border-b border-gray-200 pb-[10px] text-start dark:!border-navy-700 ${column.Header === "FIRST NAME" ? "" : "pr-30"
                        }`}
                    >
                      <p className="text-xs tracking-wide text-gray-600">
                        {column.render("Header")}
                      </p>
                    </th>
                  ))}


                  {/* <th
                  
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  > */}
                  {/* <p className="text-xs tracking-wide text-gray-600">
                      ACTIONS
                    </p> */}
                  {/* </th> */}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, index) => {

                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={index}>


                    {row.cells.map((cell, index) => {
                      let data = "";
                      // console.log(cell.value);
                      if (cell.column.Header === "ID") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {row?.index + 1}
                          </p>
                        );
                      }
                      else if (cell.column.Header === "FIRST NAME") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value !== null && cell.value !== "" ? cell.value : "First Name not mentioned"}
                          </p>
                        );
                      }
                      else if (cell.column.Header === "EMAIL") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value !== null && cell.value !== "" ? cell.value : "Email not mentioned"}
                          </p>
                        );
                      }
                      else if (cell.column.Header === "PHONE") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value !== null && cell.value !== "" ? cell.value : "Phone not mentioned"}
                          </p>
                        );
                      }
                      else if (cell.column.Header === "QBANK") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">


                            {
                              cell.value && (cell.value.length !== 0) ?
                                cell.value.map((e, i) => {
                                  return (

                                    <p style={{display:"flex"}} className="text-sm font-bold text-navy-700 dark:text-white">
                                      {i + 1}. {e?.QBank.name !== null ? e?.QBank.name : "QBank not mentioned"}


                                      <a target="_blank" rel="noopener noreferrer" className="ml-3 mt-1" onClick={() => showModalRemove(row?.original, e?.QBank.id)}>
                                        <MdCancel className="text-red-500" />
                                      </a>



                                    </p>


                                  )
                                })
                                :
                                <p className="text-sm font-bold text-red-700 dark:text-white">
                                  No QBank
                                </p>
                            }
                            {/* {cell.value !== null ? cell.value : "Qbank not assigned" } */}
                          </p>
                        );
                      } else if (cell.column.Header === "STATUS") {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              {row?.original?.isBlocked === true ? (
                                <MdCancel className="text-red-500" />
                              ) :
                              row?.original?.isActive === true ? (

                                 <MdCheckCircle className="text-green-500" />
                               ) : cell.value === "Disable" ? (
                                 <MdCancel className="text-red-500" />
                               ) : cell.value === "Error" ? (
                                 <MdOutlineError className="text-orange-500" />
                               ) :
                                row?.original?.isVerified === false ? (
                                  <MdOutlineError className="text-orange-500" />
                                ) :
                                row?.original?.isActive === false && row?.original?.isVerified === true ? (
                                  <MdOutlineError className="text-orange-500" />                                ) 
                                    : null}
                            </div>
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {row?.original?.isBlocked === true ? (
                                "Blocked"
                              ) :
                              row?.original?.isActive === true ? (
                                "Activated"
                              ) 
                              
                              : 
                              row?.original?.isVerified === false ? (
                                "Not Verified"
                                ) :
                             
                                row?.original?.isActive === false && row?.original?.isVerified === true ? (
                                  "Not Activated"
                                ) 
                             :     
                                    null}                          </p>
                          </div>
                        );
                      } else if (cell.column.Header === "USERNAME") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value !== null ? cell.value : "Username not mentioned"}
                          </p>
                        );
                      } else if (cell.column.Header === "EMAIL") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value !== null ? cell.value : "Email not mentioned"}
                          </p>
                        );
                      } else if (cell.column.Header === "COUNTRY") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value !== null ? cell.value : "Country not mentioned"}
                          </p>
                        );
                      } else if (cell.column.Header === "ACTIONS") {
                        data = (
                          <p className="text-sm font-bold text-navy-700 dark:text-white flex">


                            <Tooltip title="Approve">

                              <button
                                onClick={() => ActivateUser(row?.original?.id)}
                                className={`flex items-center text-xl hover:cursor-pointer mr-3 ${false
                                    ? "bg-none text-white hover:bg-none active:bg-none"
                                    : "bg-green-500 p-2 text-white hover:bg-green-400 dark:bg-green-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                  } linear justify-center rounded-lg font-bold transition duration-200`}
                              >
                                <MdOutlineVerified className="h-6 w-6" />
                              </button>
                            </Tooltip>


                            <Tooltip title="Block User">

                              <button
                                onClick={() => BlockUser(row?.original?.id)}
                                className={`flex items-center text-xl hover:cursor-pointer mr-3 ${false
                                    ? "bg-none text-white hover:bg-none active:bg-none"
                                    : "bg-red-500 p-2 text-white hover:bg-red-400 dark:bg-red-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                  } linear justify-center rounded-lg font-bold transition duration-200`}
                              >
                                <CgBlock className="h-6 w-6" />
                              </button>

                            </Tooltip>


                            <Tooltip title="UnBlock User">

                              <button
                                onClick={() => UnBlockUser(row?.original?.id)}
                                className={`flex items-center text-xl hover:cursor-pointer mr-3 ${false
                                    ? "bg-none text-white hover:bg-none active:bg-none"
                                    : "bg-blue-500 p-2 text-white hover:bg-blue-400 dark:bg-blue-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                  } linear justify-center rounded-lg font-bold transition duration-200`}
                              >
                                <CgUnblock className="h-6 w-6" />
                              </button>
                            </Tooltip>
                            <Tooltip title="Assign QBank">

                              <button
                                onClick={() => showModal(row?.original)}
                                className={`flex items-center text-xl hover:cursor-pointer ${false
                                    ? "bg-none text-white hover:bg-none active:bg-none"
                                    : "bg-cyan-500 p-2 text-white hover:bg-cyan-400 dark:bg-cyan-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                  } linear justify-center rounded-lg font-bold transition duration-200`}
                              >
                                <CgAssign className="h-6 w-6" />
                              </button>
                            </Tooltip>


                          </p>
                        );
                      }
                      else if (cell.column.Header === "PROGRESS") {
                        data = <Progress width="w-[68px]" value={cell.value} />;
                      }


                      return (
                        <td
                          className="pt-[14px] pb-[18px] sm:text-[14px]"
                          {...cell.getCellProps()}
                          key={index}
                        >
                          <div
                          >

                            {data}


                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

      )}

      <Modal title="Assign Qbank To User" footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" style={{ backgroundColor: "#1677ff" }} type="primary" loading={loading} onClick={handleOk}>
          Assign
        </Button>,

      ]}
        open={isModalOpen} onCancel={handleCancel}>

        <div className="my-10">
          <p className="text-sm font-semibold dark:text-white mb-2">
            Assign QBank:
          </p>
          <Select

            style={{
              minWidth: 300,
            }}
            onChange={handleChangeSelect}
            options={selectTObject}
            value={selectedValue}
          />

        </div>

      </Modal>



      <Modal title="Remove Qbank To User" footer={[
        <Button key="back" onClick={handleCancelRemove}>
          Cancel
        </Button>,
        <Button key="submit" style={{ backgroundColor: "#1677ff" }} type="primary" loading={loading} onClick={handleOkRemove}>
          Remove
        </Button>,

      ]}
        open={isModalRemoveOpen} onCancel={handleCancelRemove}>



        <div className="my-10">
          <p className="text-sm font-semibold dark:text-white mb-2">
            Do you want to remove the QBank?
          </p>


        </div>

      </Modal>


    </Card>
  );
};

export default ComplexTable;
