import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError,MdOutlineRefresh ,MdOutlineVerified} from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import Progress from "components/progress";
import { BiBlock } from "react-icons/bi";
import axios from "axios";
import { BASE_URL } from "variables/Endpoints";
import { Tooltip, notification } from 'antd';
import { CgUnblock, CgBlock } from "react-icons/cg";
import { useNavigate } from "react-router-dom";



const ComplexTable = (props) => {
  const { columnsData } = props;

  const navigate = useNavigate();

const [tableData, setTableData] = useState([])
const [api, contextHolder] = notification.useNotification();




let tokenMe = localStorage.getItem("accessToken");

  
const fetchData = async () => {
  var data = '';

  var config = {
    method: 'get',
    url: `${BASE_URL}admin/users`,
    headers: { 
      'Authorization': `Bearer ${tokenMe && tokenMe}`
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    setTableData(response?.data?.data);
  })
  .catch(function (error) {
    console.log(error);

    if((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")){
      localStorage.clear();
      navigate("/auth/sign-in")
    }else{
      api['error']({
        message: error?.response?.data?.error,
          key: 'Success Message',
      });
    }
  });
};



const ActivateUser = async (userid) => {
  var data = '';

  var config = {
    method: 'patch',
    url: `${BASE_URL}admin/users/active/${userid}`,
    headers: { 
      'Authorization': `Bearer ${tokenMe && tokenMe}`
    },
    data : data
  };
  
  axios(config)
  .then(function (response) {
    fetchData();
    api['success']({
      message: response?.data?.data,
        key: 'Success Message',
    });



  })
  .catch(function (error) {
    console.log(error);
    if((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")){
      localStorage.clear();
      navigate("/auth/sign-in")
    }else{
      api['error']({
        message: error?.response?.data?.error,
          key: 'Success Message',
      });
    }
  });
};



const BlockUser = async (userid) => {
  var data = '';

var config = {
  method: 'patch',
  url: `${BASE_URL}admin/users/block/${userid}`,
  headers: { 
    'Authorization': `Bearer ${tokenMe && tokenMe}`
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(response.data);
  fetchData();
  api['success']({
    message: response?.data?.data,
      key: 'Success Message',
  });
})
.catch(function (error) {
  console.log(error);
  if((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")){
    localStorage.clear();
    navigate("/auth/sign-in")
  }else{
    api['error']({
      message: error?.response?.data?.error,
        key: 'Success Message',
    });
  }
});

};



const UnBlockUser = async (userid) => {
  var data = '';

var config = {
  method: 'patch',
  url: `${BASE_URL}admin/users/unblock/${userid}`,
  headers: { 
    'Authorization': `Bearer ${tokenMe && tokenMe}`
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(response.data);
  fetchData();
  api['success']({
    message: response?.data?.data,
      key: 'Success Message',
  });
})
.catch(function (error) {
  console.log(error);
  if((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")){
    localStorage.clear();
    navigate("/auth/sign-in")
  }else{
    api['error']({
      message: error?.response?.data?.error,
        key: 'Success Message',
    });
  }
});

};



useEffect(() => {
  fetchData();
}, []);




  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const handleCellClick = (rowIndex, columnIndex) => {
    // Handle the cell click event here
    console.log("Cell clicked:", rowIndex, columnIndex);
  };
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = tableData.length + 10;

  return (
    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
                          {contextHolder}

      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Users Table
        </div>
        <button
          onClick={() => fetchData()}
          className={`flex items-center text-xl hover:cursor-pointer ${
            false
              ? "bg-none text-white hover:bg-none active:bg-none"
              : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          <MdOutlineRefresh className="h-6 w-6" />
        </button>
        
        
              </div>

      <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {/* <th
                  
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                > */}
                  {/* <p className="text-xs tracking-wide text-gray-600">
                    ID
                  </p> */}
                {/* </th> */}
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-600">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}

{/* <th
                  
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  > */}
                    {/* <p className="text-xs tracking-wide text-gray-600">
                      ACTIONS
                    </p> */}
                  {/* </th> */}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {

              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>


                  {row.cells.map((cell, index) => {
                    let data = "";
                    
                    if (cell.column.Header === "ID") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {row?.index + 1 }
                        </p>
                      );
                    } 
                    else if (cell.column.Header === "EMAIL") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value !== null ? cell.value : "Email not mentioned" }
                        </p>
                      );
                    } else if (cell.column.Header === "STATUS") {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className={`rounded-full text-xl`}>
                          {row?.original?.isBlocked === true ? (
                            <MdCancel className="text-red-500" />
                                                        ) :
                                                        row?.original?.isVerified === false ? (
                                                          <MdOutlineError className="text-orange-500" />
                                                          ) :
                                                          row?.original?.isActive === false ? (
  <MdOutlineError className="text-orange-500" />
                                                        ) :
                          row?.original?.isActive === true && row?.original?.isBlocked === false && row?.original?.isVerified === true ? (
                              <MdCheckCircle className="text-green-500" />
                            ) : cell.value === "Disable" ? (
                              <MdCancel className="text-red-500" />
                            ) : cell.value === "Error" ? (
                              <MdOutlineError className="text-orange-500" />
                            ) : null}
                          </div>
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {row?.original?.isBlocked === true ? (
                                "Blocked"
                                ) :
                                row?.original?.isVerified === false ? (
                              "Not Verified"
                                                              ) : 
  row?.original?.isActive === false ? (
    "Not Activated"
) :
                                                        row?.original?.isActive === true && row?.original?.isBlocked === false && row?.original?.isVerified === true ? (
                              "Activated"
                            ) : cell.value === "Disable" ? (
                              <MdCancel className="text-red-500" />
                            ) : cell.value === "Error" ? (
                              <MdOutlineError className="text-orange-500" />
                            ) : null}                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "USERNAME") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value !== null ? cell.value : "Username not mentioned" }
                        </p>
                      );
                    } else if (cell.column.Header === "EMAIL") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value !== null ? cell.value : "Email not mentioned" }
                        </p>
                      );
                    }else if (cell.column.Header === "COUNTRY") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value !== null ? cell.value : "Country not mentioned" }
                        </p>
                      );
                    }  else if (cell.column.Header === "ACTIONS") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white flex">


<Tooltip title="Approve">

                           <button
          onClick={() => ActivateUser(row?.original?.id)}
          className={`flex items-center text-xl hover:cursor-pointer mr-5 ${
            false
              ? "bg-none text-white hover:bg-none active:bg-none"
              : "bg-green-500 p-2 text-white hover:bg-green-400 dark:bg-green-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          <MdOutlineVerified className="h-6 w-6" />
        </button>
</Tooltip>


<Tooltip title="Block User">

        <button
          onClick={() => BlockUser(row?.original?.id)}
          className={`flex items-center text-xl hover:cursor-pointer mr-5 ${
            false
              ? "bg-none text-white hover:bg-none active:bg-none"
              : "bg-red-500 p-2 text-white hover:bg-red-400 dark:bg-red-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          <CgBlock className="h-6 w-6" />
        </button>

        </Tooltip>


        <Tooltip title="UnBlock User">

        <button
          onClick={() => UnBlockUser(row?.original?.id)}
          className={`flex items-center text-xl hover:cursor-pointer ${
            false
              ? "bg-none text-white hover:bg-none active:bg-none"
              : "bg-blue-500 p-2 text-white hover:bg-blue-400 dark:bg-blue-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
          } linear justify-center rounded-lg font-bold transition duration-200`}
        >
          <CgUnblock className="h-6 w-6" />
        </button>
        </Tooltip>

                        </p>
                      );
                    } 
                     else if (cell.column.Header === "PROGRESS") {
                      data = <Progress width="w-[68px]" value={cell.value} />;
                    }


                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
<div
                          onClick={() => handleCellClick(index, index)}
                        >
                          
                                                  {data}

                          
                          </div>                        
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default ComplexTable;
