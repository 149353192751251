import React from 'react'
import { Button, notification, Upload, message } from 'antd';
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const Uploader = ({ setUploadedLink , labelName}) => {
  const [api, contextHolder] = notification.useNotification();

  let tokenMe = localStorage.getItem("accessToken");
  const navigate = useNavigate();



  const openNotificationError = (placement, msg) => {
    api.error({
      message: msg,
      placement,
    });
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append('images', file);


      // Axios POST request
      const response = await axios.post('https://backend.nextgentestprep.com/api/admin/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          'Authorization': `Bearer ${tokenMe && tokenMe}`
        }
      });

      if (response.status === 200) {
        const data = response.data;
        const hostedLink = data?.imageUrls;
        setUploadedLink(hostedLink);
        onSuccess();
        message.success('Upload successful');
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      onError(error);
      message.error('Upload failed');
      if ((error === "jwt expired") || (error === "jwt malformed")) {        // Handle token expiration or unauthorized access here
        localStorage.clear();
        navigate("/auth/sign-in");
      } else {
        // Handle other errors here
        openNotificationError('top', error.message);
      }
    }
  };

  return (
    <div className="mt-2">
      {contextHolder}
      <label
        htmlFor="image"
        className="text-sm text-navy-700 dark:text-white ml-3 font-bold mr-6"

      >
       {labelName} Image
      </label>

      <Upload
        customRequest={customRequest}
        id="image"
        listType="picture"
        maxCount={1}
        showUploadList={true}
        fileList={[]}
      >
        <Button className='flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none' icon={<UploadOutlined className="mb-1" />}>Upload (Max: 1)</Button>
      </Upload>
    </div>

  )
}

export default Uploader