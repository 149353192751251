import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdCheckCircle, MdCancel, MdOutlineError, MdOutlineRefresh, MdCreate, MdFileUpload, MdBlock } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import Progress from "components/progress";
import axios from "axios";
import { BASE_URL } from "variables/Endpoints";
import { Col, Collapse, Divider, Empty, Row, Tooltip, notification } from 'antd';
import { Button, Modal, Space, Drawer } from 'antd';
import { Checkbox } from 'antd';

import { CgUnblock, CgBlock, CgTrashEmpty, CgAddR } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import InputField from "components/fields/InputField";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import OptionFormModal from "./OptionFormModal";
import CSVReader from 'react-csv-reader';
import Uploader from "components/fields/Uploader";


const ComplexTable = (props) => {
  const { columnsData } = props;
  const navigate = useNavigate();
  const [qbankname, setqbankname] = useState("");
  const [qbankdesc, setqbankdesc] = useState("");
  const [qbanktype, setqbanktype] = useState("");
  const [bulktestname, setbulktestname] = useState("");
  const [bulktestdesc, setbulktestdesc] = useState("");
  const [testname, settestname] = useState("");
  const [testdesc, settestdesc] = useState("");
  const [testduration, settestduration] = useState("");
  const [testqbank, settestqbank] = useState("");
  const [questionname, setquestionname] = useState("");
  const [questiondesc, setquestiondesc] = useState("");
  const [isLoader, setisLoader] = useState(true);
  const [testId, settestId] = useState("");
  const [testDetails, settestDetails] = useState([]);
  const [tableData, setTableData] = useState([])
  const [api, contextHolder] = notification.useNotification();
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openTestModal, setopenTestModal] = useState(false);
  const [openBulkModal, setopenBulkModal] = useState(false);
  const [openQuestionModal, setopenQuestionModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditQuestionModal, setOpenEditQuestionModal] = useState(false);
  const [editQuestionId, setEditQuestionId] = useState("");
  const [editQuestionName, setEditQuestionName] = useState("");
  const [editExplanation, setEditExplanation] = useState("");
  const [openAddOptionModal, setOpenAddOptionModal] = useState(false);
  const [addOptionQuestionId, setAddOptionQuestionId] = useState("");
  const [optionName, setOptionName] = useState("");
  const [optionCorrect, setOptionCorrect] = useState(false);
  const [openEditOptionModal, setOpenEditOptionModal] = useState(false);
  const [editOptionId, setEditOptionId] = useState("");
  const [editOptionName, setEditOptionName] = useState("");
  const [editOptionCorrect, setEditOptionCorrect] = useState(false);
  
  const [csvData, setCsvData] = useState([]);
  
  
  const [optionCreateImageURL, setOptionCreateImageURL] = useState(null);
  const [optionEditImageURL, setOptionEditImageURL] = useState(null);
  const [questionEditImageURL, setQuestionEditImageURL] = useState(null);
  const [questionExplanationEditImageURL, setQuestionExplanationEditImageURL] = useState(null);


  const handleFileUpload = data => {
    setCsvData(data);
  };







  const showModal = () => {
    setOpen(true);
  };
  const showTestModal = () => {
    setopenTestModal(true);
  };
  const showBulkUploadModal = () => {
    setopenBulkModal(true);
  };
  const showQuestionModal = (id) => {

    settestId(id);
    setopenQuestionModal(true);
  };
  const EditQuestion = (id, name, explanation, qImage, qExplanationImage) => {

    setEditQuestionId(id);
    setEditQuestionName(name);
    setEditExplanation(explanation);
setQuestionEditImageURL(qImage)
setQuestionExplanationEditImageURL(qExplanationImage)
    setOpenEditQuestionModal(true);
  };
  const AddOptions = (id) => {

    setAddOptionQuestionId(id);
    setOpenAddOptionModal(true);
  };
  const EditOption = (id, name, correct, oeImage) => {

    setEditOptionId(id);
    setEditOptionName(name);
    setEditOptionCorrect(correct);
    setOpenEditOptionModal(true);
    setOptionEditImageURL(oeImage)
  };

  const BlockQuestion = async (id) => {
    var data = '';

    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/question/block/${id}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        fetchData();

        openNotificationsucess('top', response?.data?.data);
        setOpenDrawer(false)

      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          openNotificationError('top', error?.response?.data?.error);

          // api['error']({
          //   message: error?.response?.data?.error,
          //     key: 'Success Message',
          // });
        }
      });

  };


  const UnBlockQuestion = async (id) => {
    var data = '';

    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/question/unblock/${id}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        fetchData();

        openNotificationsucess('top', response?.data?.data);
        setOpenDrawer(false)

      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          openNotificationError('top', error?.response?.data?.error);
        }
      });

  };
  const DeleteQuestion = async (id) => {
    var data = '';

    var config = {
      method: 'delete',
      url: `${BASE_URL}admin/question/${id}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {

        fetchData();
        openNotificationsucess('top', response?.data?.data);
        setOpenDrawer(false)
      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          openNotificationError('top', error?.response?.data?.error);

          // api['error']({
          //   message: error?.response?.data?.error,
          //     key: 'Success Message',
          // });
        }
      });

  };
 
 
 
  const BlockTest = async (id) => {
    var data = '';

    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/test/block/${id}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        fetchData();

        openNotificationsucess('top', response?.data?.data);
        setOpenDrawer(false)

      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          openNotificationError('top', error?.response?.data?.error);

          // api['error']({
          //   message: error?.response?.data?.error,
          //     key: 'Success Message',
          // });
        }
      });

  };


  const UnBlockTest = async (id) => {
    var data = '';

    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/test/unblock/${id}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        fetchData();

        openNotificationsucess('top', response?.data?.data);
        setOpenDrawer(false)

      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          openNotificationError('top', error?.response?.data?.error);
        }
      });

  };
  const DeleteTest = async (id) => {
    var data = '';

    var config = {
      method: 'delete',
      url: `${BASE_URL}admin/test/${id}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {

        fetchData();
        openNotificationsucess('top', response?.data?.data);
        setOpenDrawer(false)
      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          openNotificationError('top', error?.response?.data?.error);

          // api['error']({
          //   message: error?.response?.data?.error,
          //     key: 'Success Message',
          // });
        }
      });

  };





  const DeleteOption = async (id) => {
    var data = '';

    var config = {
      method: 'delete',
      url: `${BASE_URL}admin/option/${id}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {

        fetchData();
        openNotificationsucess('top', response?.data?.data);
        setOpenDrawer(false)
      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          openNotificationError('top', error?.response?.data?.error);

          // api['error']({
          //   message: error?.response?.data?.error,
          //     key: 'Success Message',
          // });
        }
      });

  };

  const handleOk = () => {
    setLoading(true);

    CreateQbanks();

  };
  const handleEditQuestionCreate = () => {
    setLoading(true);

    EditQuestionApiCallFunction()
  };

  const handleQuestionCreate = () => {
    setLoading(true);
    // CreateTests();

  };


  const handleTestCreate = () => {
    setLoading(true);

    CreateTests();

  };
  const handleOptionAdd = () => {
    setLoading(true);

    CreateOption();

  };
  const handleOptionEdit = () => {
    setLoading(true);

    EditzOption();

  };




  const handleCancel = () => {
    setOpen(false);
  };
  const handleTestCancel = () => {
    setopenTestModal(false);
  };

  const handleBulkCancel = () => {
    setopenBulkModal(false);
  };
  const handleEditQuestionCancel = () => {
    setOpenEditQuestionModal(false);
  };
  const handleAddOptionCancel = () => {
    setOpenAddOptionModal(false);
    setOptionCreateImageURL(null)
  };
  const handleEditOptionCancel = () => {
    setOpenEditOptionModal(false);
    setOptionEditImageURL(null)
  };


  let tokenMe = localStorage.getItem("accessToken");



  const [childrenDrawer, setChildrenDrawer] = useState(false);

  const showDrawer = (id) => {

    settestDetails(id);

    setOpenDrawer(true);


  };





  const NavigateToTests = (id) => {
    navigate(`/tests/${id}`, { params: { id } });
  };

  const fetchData = async () => {
    setisLoader(true);

    var data = '';


    var config = {
      method: 'get',
      url: `${BASE_URL}admin/qbank`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setisLoader(false);
        setTableData(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);

        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          api['error']({
            message: error?.response?.data?.error,
            key: 'Success Message',
          });
        }
      });
  };

  const openNotificationsucess = (placement, msg) => {
    api.success({
      message: msg,
      placement,
    });
  };

  const openNotificationError = (placement, msg) => {
    api.error({
      message: msg,
      placement,
    });
  };

  const CreateTests = async () => {

    var data = {
      "name": testname,
      "description": testdesc,
      // "duration": testduration,
      "qbankid": testDetails?.id,
    };
    var config = {
      method: 'post',
      url: `${BASE_URL}admin/test`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };



    // if(response?.data?.code === 200 && response?.data?.success === true){


    axios(config)
      .then(function (response) {

        openNotificationsucess('top', 'Successfully Created Test');
        setopenTestModal(false);

        fetchData();
        // api['success']({
        //   message: 'Successfully Created QBank',
        //     key: 'SuccessMSG',
        // });
        setLoading(false);
        setOpenDrawer(false)
      })
      .catch(function (error) {


        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          setLoading(false);
          setOpen(false);
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          setLoading(false);

          openNotificationError('top', error?.response?.data?.error);


        }
      });
  };

  const CreateOption = async () => {

    var data = {
      "questionid": addOptionQuestionId,
      "name": optionName,
      "image": optionCreateImageURL,
      "istrue": optionCorrect,
    };
    var config = {
      method: 'post',
      url: `${BASE_URL}admin/option`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };



    // if(response?.data?.code === 200 && response?.data?.success === true){


    axios(config)
      .then(function (response) {

        openNotificationsucess('top', 'Successfully Created Option');
        setOpenAddOptionModal(false);

        fetchData();
        // api['success']({
        //   message: 'Successfully Created QBank',
        //     key: 'SuccessMSG',
        // });
        setLoading(false);
        setOpenDrawer(false)
      })
      .catch(function (error) {


        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          setLoading(false);
          setOpen(false);
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          setLoading(false);

          openNotificationError('top', error?.response?.data?.error);


        }
      });
  };


  const EditzOption = async () => {

    var data = {
      "optionid": editOptionId,
      "name": editOptionName,
      "image":optionEditImageURL,
      // "duration": testduration,
      "istrue": editOptionCorrect,
    };
    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/option`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };



    // if(response?.data?.code === 200 && response?.data?.success === true){


    axios(config)
      .then(function (response) {

        openNotificationsucess('top', 'Successfully Edited Option');
        setOpenEditOptionModal(false);

        fetchData();
        setOpenDrawer(false)
        // api['success']({
        //   message: 'Successfully Created QBank',
        //     key: 'SuccessMSG',
        // });
        setLoading(false);
      })
      .catch(function (error) {


        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          setLoading(false);
          setOpen(false);
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          setLoading(false);

          openNotificationError('top', error?.response?.data?.error);


        }
      });
  };



  const EditQuestionApiCallFunction = async () => {

    var data = {
      "questionid": editQuestionId,
      "description": editQuestionName,
      "image": questionEditImageURL,
      "explanation": editExplanation,
      "explanation_img": questionExplanationEditImageURL,

    };
    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/question`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };



    // if(response?.data?.code === 200 && response?.data?.success === true){


    axios(config)
      .then(function (response) {

        openNotificationsucess('top', 'Successfully Updated Question and Explanation');
        setOpenEditQuestionModal(false);

        fetchData();
        // api['success']({
        //   message: 'Successfully Created QBank',
        //     key: 'SuccessMSG',
        // });
        setLoading(false);
        setOpenDrawer(false);
      })
      .catch(function (error) {


        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          setLoading(false);
          setOpen(false);
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          setLoading(false);

          openNotificationError('top', error?.response?.data?.error);


        }
      });
  };



  const handleModalCreate = () => {

    if (csvData.length === 0) {
      console.log('No CSV data available.');
      return;
    }

    setLoading(true);


    // const data = {
    //   qbankid: testDetails?.id,
    //   name: bulktestname,
    //   description: bulktestdesc,
    //   question: csvData.map(item => ({
    //     description: item[0],
    //     explanation: item[1],
    //     options: [
    //       {
    //         name: item[2],
    //         isTrue: item[3] === 'true'
    //       },
    //       {
    //         name: item[4],
    //         isTrue: item[5] === 'true'
    //       },
    //       {
    //         name: item[6],
    //         isTrue: item[7] === 'true'
    //       }
    //     ]
    //   }))
    // };

    // const filteredQuestion = data.question.filter(q => q.description);

    // // Create the final formatted object
    // const formattedData = {
    //   qbankid: data.qbankid,
    //   name: data.name,
    //   description: data.description,
    //   question: filteredQuestion.map(question => {
    //     const options = question.options.filter(option => option.name);
    //     return {
    //       description: question.description,
    //       explanation: question.explanation,
    //       options: options
    //     };
    //   })
    // };

    // const convertResponse = (response) => {
    //   const headers = response[0];
    //   const data = response.slice(1).filter(row => row.length > 1);
    //   const result = {
    //     qbankid: testDetails?.id,
    //     name: bulktestname,
    //     description: bulktestdesc,
    //     question: data.map(row => {
    //       const [question, ...options] = row;
    //       const correctAnswers = row[headers.indexOf("Correct Answers")];
    //       return {
    //         description: question,
    //         explanation: row[headers.indexOf("Explanation")],
    //         options: options.slice(0, headers.indexOf("Correct Answers") - 1).map((option, index) => ({
    //           name: option,
    //           isTrue: correctAnswers.includes(String.fromCharCode(65 + index))
    //         }))
    //       };
    //     })
    //   };
    //   return result;
    // };


    // const convertedData = convertResponse(csvData);



    const convertResponse = (response) => {
      const headers = response[0];
      const data = response.slice(1).filter(row => row.length > 1);
      const result = {
        qbankid: testDetails?.id,
        name: bulktestname,
        description: bulktestdesc,
        question: data && data.length !== 0 && data.map(row => {
          const [question, ...options] = row;
          const correctAnswers = row[headers.indexOf("Correct Answers")];
          const explanation = row[headers.indexOf("Explanation")];
          const questionOptions = options.slice(0, headers.indexOf("Correct Answers") - 1).map((option, index) => ({
            name: option,
            isTrue: correctAnswers.includes(String.fromCharCode(65 + index))
          })).filter(option => option.name !== ""); // Exclude empty options
          return {
            description: question,
            explanation: explanation,
            options: questionOptions
          };
        })
      };
      return result;
    };

    const convertedData = convertResponse(csvData);



    console.log('formattedData', convertedData);



    var config = {
      method: 'post',
      url: `${BASE_URL}admin/test/bulk`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: convertedData
    };





    axios(config)
      .then(function (response) {

        openNotificationsucess('top', 'Successfully Created QBank');
        fetchData();
        // api['success']({
        //   message: 'Successfully Created QBank',
        //     key: 'SuccessMSG',
        // });
        setLoading(false);
        setOpenDrawer(false)

      })
      .catch(function (error) {


        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          setLoading(false);
          setOpen(false);
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          setLoading(false);

          openNotificationError('top', error?.response?.data?.error);


        }
      });




  };









  const CreateQbanks = async () => {

    var data = {
      "name": qbankname,
      "description": qbankdesc,
      "type": qbanktype,
    };

    console.log(data);
    var config = {
      method: 'post',
      url: `${BASE_URL}admin/qbank`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };



    // if(response?.data?.code === 200 && response?.data?.success === true){


    axios(config)
      .then(function (response) {

        openNotificationsucess('top', 'Successfully Created QBank');
        fetchData();
        // api['success']({
        //   message: 'Successfully Created QBank',
        //     key: 'SuccessMSG',
        // });
        setLoading(false);
        setOpenDrawer(false)
      })
      .catch(function (error) {


        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          setLoading(false);
          setOpen(false);
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          setLoading(false);

          openNotificationError('top', error?.response?.data?.error);


        }
      });
  };





  const BlockUser = async (userid) => {
    var data = '';

    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/qbank/block/${userid}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        fetchData();

        openNotificationsucess('top', response?.data?.data);
        setOpenDrawer(false)

      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {
          openNotificationError('top', error?.response?.data?.error);

          // api['error']({
          //   message: error?.response?.data?.error,
          //     key: 'Success Message',
          // });
        }
      });

  };



  const UnBlockUser = async (userid) => {
    var data = '';

    var config = {
      method: 'patch',
      url: `${BASE_URL}admin/qbank/unblock/${userid}`,
      headers: {
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        fetchData();
        openNotificationsucess('top', response?.data?.data);

        setOpenDrawer(false)

      })
      .catch(function (error) {
        console.log(error);
        if ((error?.response?.data?.error === "jwt expired") || (error?.response?.data?.error === "jwt malformed")) {
          localStorage.clear();
          navigate("/auth/sign-in")
        } else {

          openNotificationError('top', error?.response?.data?.error);
          // api['error']({
          //   message: error?.response?.data?.error,
          //     key: 'Success Message',
          // });
        }
      });

  };



  useEffect(() => {
    fetchData();
  }, []);




  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);


  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows, // Use 'rows' instead of 'rows' to access all rows
    prepareRow,
  } = tableInstance;


  const { Panel } = Collapse;

  return (




    <Card extra={"w-full h-full p-4 sm:overflow-x-auto"}>
      {contextHolder}

      <div className="flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          QBank Table
        </div>

        <div className="flex">
          <Tooltip title="Refresh">
            <button
              onClick={() => fetchData()}
              className={`flex items-center text-xl hover:cursor-pointer ${false
                ? "bg-none text-white hover:bg-none active:bg-none"
                : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                } linear justify-center rounded-lg font-bold transition duration-200`}
            >
              <MdOutlineRefresh className="h-6 w-6" />
            </button>

          </Tooltip>

          <Tooltip title="Create QBank">
            <button
              onClick={() => showModal()}
              className={`flex items-center text-xl hover:cursor-pointer ml-5 ${false
                ? "bg-none text-white hover:bg-none active:bg-none"
                : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                } linear justify-center rounded-lg font-bold transition duration-200`}
            >
              <MdCreate className="h-6 w-6" />
            </button>
          </Tooltip>
        </div>
      </div>
      <Drawer
        title="Tests"
        width={720}
        closable={true}
        onClose={() => setOpenDrawer(false)}
        visible={openDrawer}

        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Tooltip title="Create Tests">
              <button
                onClick={() => showTestModal()}
                className={`flex items-center text-xl hover:cursor-pointer ${false
                  ? "bg-none text-white hover:bg-none active:bg-none"
                  : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                  } linear justify-center rounded-lg font-bold transition duration-200`}
              >
                <MdCreate className="h-6 w-6" />
              </button>
            </Tooltip>
            <Tooltip title="Bulk Test Upload">
              <button
                onClick={() => showBulkUploadModal()}
                className={`flex items-center text-xl hover:cursor-pointer ${false
                  ? "bg-none text-white hover:bg-none active:bg-none"
                  : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                  } linear justify-center rounded-lg font-bold transition duration-200`}
              >
                <MdFileUpload className="h-6 w-6" />
              </button>
            </Tooltip>
          </Space>

        }

      >

        <Collapse defaultActiveKey={['1']} accordion>
          {

            testDetails && testDetails?.Tests && (testDetails?.Tests.length !== 0) ? testDetails?.Tests.map((e, i) => {
              return (
                <Panel header={e?.name + " ( " + (e?.isactive === true ? "Active" : "Unactive") + " )"} extra={ <div className="flex">

              
                <Tooltip title="Block Test" className="mx-3">
                  
                    <MdBlock
                    onClick={() => BlockTest(e?.id)}
                     className="h-7 w-7 text-navy-300" />
                </Tooltip>

                <Tooltip title="UnBlock Test" className="mx-3">
                 
                    <CgUnblock
                    onClick={() => UnBlockTest(e?.id)}
                     className="h-7 w-7 text-blue-600" />
                </Tooltip>
                <Tooltip title="Delete Test" className="ml-3">
                 
                    <CgTrashEmpty 
                    onClick={() => DeleteTest(e?.id)}
                     className="h-7 w-7 text-red-700" />
                </Tooltip>
              </div>}  key={i + 1}>
                  <Row className="my-2">
                    <Col span={12}>
                      <p className="text-xs tracking-wide"><b>Name:</b> {e?.name}</p>
                    </Col>

                    <Col span={12}>
                      <p className="text-xs tracking-wide"><b>Description:</b> {e?.description}</p>
                    </Col>
                  </Row>
                  {/* <Row className="my-2"> */}
                  {/* <Col span={12}>
      <p className="text-xs tracking-wide"><b>Duration:</b> {e?.duration}</p>
      </Col> */}
                  {/* <Col span={12}>
      <p className="text-xs tracking-wide"><b>Category:</b> {e?.category}</p>
      </Col> */}

                  {/* </Row> */}
                  <Row className="my-2">
                    <Col span={12}>
                      <p className="text-xs tracking-wide"><b>Status:</b> {e?.isactive === true ? "Active" : "Unactive"}</p>
                    </Col>


                  </Row>

                  <div className="flex items-center justify-between">


                    <p className="text-3xl font-medium dark:text-white mb-5 mt-10">Questions:</p>

                    <Tooltip title="Create Question">
                      <button
                        onClick={() => showQuestionModal(e?.id)}
                        className={`flex items-center text-xl hover:cursor-pointer ${false
                          ? "bg-none text-white hover:bg-none active:bg-none"
                          : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                          } linear justify-center rounded-lg font-bold transition duration-200`}
                      >
                        <MdCreate className="h-6 w-6" />
                      </button>
                    </Tooltip>



                  </div>




                  {
                    e?.Questions && e?.Questions.length !== 0 ? e?.Questions.map((ques, qkey) => {
                      return (
                        <>
                          <br />
                          <div className="flex justify-between mb-10" style={{ alignItems: "center" }}>
                            <p className={ques?.isactive === true ? "text-xl font-medium dark:text-white" : "text-xl font-medium text-red-900 dark:text-white"}>Question No. {qkey + 1}: </p>
                            <div className="flex">

                              <Tooltip title="Edit Question">
                                <button
                                  onClick={() => EditQuestion(ques?.id, ques?.description, ques?.explanation && ques?.explanation !== null ? ques?.explanation.replace(/�/g, '') : "", ques?.image, ques?.explanationImg)}
                                  className={`flex items-center text-md hover:cursor-pointer mr-4
                                  bg-green-500 p-2 text-white hover:bg-green-700 dark:bg-green-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg transition duration-200 border`}
                                >
                                  <MdCreate className="h-6 w-6" />
                                </button>
                              </Tooltip>
                              <Tooltip title="Block Question">
                                <button
                                  onClick={() => BlockQuestion(ques?.id)}
                                  className={`flex items-center text-md hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg transition duration-200 border`}
                                >
                                  <MdBlock className="h-6 w-6" />
                                </button>
                              </Tooltip>

                              <Tooltip title="UnBlock Question">
                                <button
                                  onClick={() => UnBlockQuestion(ques?.id)}
                                  className={`flex items-center text-md ml-4 hover:cursor-pointer bg-blue-500 p-2 text-white hover:bg-blue-400 dark:bg-blue-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg transition duration-200`}
                                >
                                  <CgUnblock className="h-6 w-6" />
                                </button>
                              </Tooltip>
                              <Tooltip title="Delete Question">
                                <button
                                  onClick={() => DeleteQuestion(ques?.id)}
                                  className={`flex items-center text-md ml-4 hover:cursor-pointer bg-red-6 00 p-2 text-white bg-red-600 hover:bg-red-700 dark:bg-red-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg transition duration-200`}
                                >
                                  <CgTrashEmpty className="h-6 w-6" />
                                </button>
                              </Tooltip>
                            </div>
                          </div>

                          <p className={ques?.isactive === true ? "text-lg mb-5 font-medium dark:text-white" : "text-lg mb-5 font-medium text-red-900 dark:text-white"}>Q{qkey + 1}. 
                          {ques?.image && ques?.image !== null && ques?.image !== "" && <img className="m-2" style={{width:"100%"}} src={ques?.image} />}
                          {ques?.description && (ques?.isactive !== true) ? ques?.description + " ( Blocked ) " : ques?.description && (ques?.isactive === true) ? ques?.description : "Question Not Found"}</p>

                          <div className="flex justify-end mb-10 mt-4" style={{ alignItems: "center" }}>

                            <Tooltip title="Add Option">
                              <button
                                onClick={() => AddOptions(ques?.id)}
                                className={`flex items-center text-md hover:cursor-pointer
                                  bg-green-500 p-2 text-white hover:bg-green-700 dark:bg-green-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg transition duration-200 border`}
                              >
                                <CgAddR className="h-6 w-6" /> &nbsp; Add Option
                              </button>
                            </Tooltip>
                          </div>

                          {
                            ques?.Options && ques?.Options.length !== 0 ? ques?.Options?.map((options, opKey) => {
                              return (


                                <div
                                  style={{ borderRadius: 8 }}
                                  className={`flex items-center justify-between p-3 mb-3 ${(options?.istrue === true) ? "bg-green-300 border-green-500" : "bg-red-300 border-red-600"} border `}        >



                                  <span className="flex items-center">{opKey + 1}. { options?.image && options?.image !== null && options?.image !== "" && <a className="mx-2" style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '48px', height: '48px',display:"block", 
 lineHeight: '60px',
    textAlign: 'center',
    flex: 'none'}}  href={options?.image} target="_blank" rel="noopener noreferrer">
      <img src={options?.image} style={{display:"block", width:"100%", height:"100%", overflow:"hidden"}} alt="yyy.png" /></a>} {options?.name} <b>{options?.isactive === false ? "( Not Activated )" : null}</b></span>
                                  <div className="flex items-center">

                                    {options?.istrue === true ? (
                                      <CheckCircleOutlined className="text-green-600" style={{ fontSize: 21 }} />
                                    ) : (
                                      <CloseCircleOutlined className="text-red-700" style={{ fontSize: 21 }} />
                                    )}


                                    <Tooltip title="Update Option">
                                      <button
                                        onClick={() => EditOption(options?.id, options?.name, options?.istrue, options?.image)}
                                        className={`flex items-center text-md ml-4 hover:cursor-pointer p-2 text-white bg-green-600 hover:bg-green-700 dark:bg-green-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg transition duration-200`}
                                      >
                                        <MdCreate className="h-6 w-6" />
                                      </button>
                                    </Tooltip>
                                    <Tooltip title="Delete Option">
                                      <button
                                        onClick={() => DeleteOption(options?.id)}
                                        className={`flex items-center text-md ml-4 hover:cursor-pointer p-2 text-white bg-red-600 hover:bg-red-700 dark:bg-red-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg transition duration-200`}
                                      >
                                        <CgTrashEmpty className="h-6 w-6" />
                                      </button>
                                    </Tooltip>

                                  </div>

                                </div>
                              )
                            })
                              :

                              <>
                                
                                <div
                                  style={{ borderRadius: 8 }}
                                  className="flex items-center justify-between p-3 bg-red-200 border-red-400 border"
                                >

                                  <span>No Options Found</span>
                                  {false ? (
                                    <CheckCircleOutlined className="text-green-800" />
                                  ) : (
                                    <CloseCircleOutlined className="text-red-800" />
                                  )}
                                </div>
                              </>
                          }

                          <p className={ques?.isactive === true ? "text-md mb-5 dark:text-white my-5" : "text-md mb-5 text-red-900 dark:text-white"}><b>Explanation: </b>
{ques?.explanationImg && ques?.explanationImg !== null && ques?.explanationImg !== "" && <img className="m-2" style={{width:"100%"}} src={ques?.explanationImg} />}
                          {ques?.explanation && (ques?.explanation !== null) ? ques?.explanation.replace(/�/g, '') : "Explanation Not Found"}</p>

                          <br />

                          <Divider plain orientation="center"><p className="font-medium">End Of Question No. {qkey + 1}</p></Divider>
                        </>
                      )
                    })
                      :
                      <p className="text-xs tracking-wide">No Questions Found</p>

                  }

                </Panel>
              )
            })
              :
              <Panel header="No Test Found" key="1">No Details Found</Panel>

          }

          {/* <Panel header="This is panel header 2" key="2">
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam itaque sunt ab hic quis, rerum dignissimos ratione obcaecati quo, accusantium dolor reiciendis omnis? Error sit quisquam sapiente similique perspiciatis fugit.</p>
    </Panel>
    <Panel header="This is panel header 3" key="3">
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam itaque sunt ab hic quis, rerum dignissimos ratione obcaecati quo, accusantium dolor reiciendis omnis? Error sit quisquam sapiente similique perspiciatis fugit.</p>
    </Panel> */}
        </Collapse>



      </Drawer>




      <OptionFormModal
        openQuestionModal={openQuestionModal}
        setopenQuestionModal={setopenQuestionModal}
        fetchData={fetchData}
        setOpenDrawer={setOpenDrawer}
        handleQuestionCreate={handleQuestionCreate}
        testID={testId}
      />


      <Modal
        open={openTestModal}
        title="Create Test"
        onOk={handleTestCreate}
        onCancel={handleTestCancel}
        footer={[
          <Button key="back" onClick={handleTestCancel}>
            Cancel
          </Button>,
          <Button key="submit" style={{ backgroundColor: "#1677ff" }} type="primary" loading={loading} onClick={handleTestCreate}>
            Submit
          </Button>,

        ]}
      >
        <InputField
          onChange={(e) => settestname(e.target.value)}
          variant="qbank"
          extra="mb-3"
          label="Name*"
          placeholder="Enter Test Name"
          id="name"
          type="text"
        />
        <InputField
          onChange={(e) => settestdesc(e.target.value)}
          variant="qbank*"
          extra="mb-3"
          label="Description*"
          placeholder="Enter Test Description"
          id="desc"
          type="text"
        />
        {/* <InputField
        onChange={(e) => settestduration(e.target.value)}
          variant="qbank"
          extra="mb-3"
          label="Duration*"
          placeholder="Enter Test Duration"
          id="type"
          type="text"
        /> */}

      </Modal>



      <Modal
        open={openEditQuestionModal}
        title="Edit Question"
        onOk={handleEditQuestionCreate}
        onCancel={handleEditQuestionCancel}
        footer={[
          <Button key="back" onClick={handleEditQuestionCancel}>
            Cancel
          </Button>,
          <Button key="submit" style={{ backgroundColor: "#1677ff" }} type="primary" loading={loading} onClick={handleEditQuestionCreate}>
            Edit
          </Button>,

        ]}
      >
        <InputField
          onChange={(e) => setEditQuestionName(e.target.value)}
          variant="qbank"
          extra="mb-3"
          value={editQuestionName}
          label="Question*"
          placeholder="Enter Question"
          id="name"
          type="text"
        />
        <div className="my-2 flex items-center">

<Uploader setUploadedLink={setQuestionEditImageURL} /> {questionEditImageURL && questionEditImageURL !== null && questionEditImageURL !== "" && <img width="55px" className="mx-2 mt-2" src={questionEditImageURL} />}
        </div>
        <div className="mt-4">
          <label
            htmlFor="feedbackInput"
            className="text-sm text-navy-700 dark:text-white ml-3 font-bold"
          >
            Explanation*
          </label>
          <textarea
            onChange={(e) => setEditExplanation(e.target.value)}
            variant="qbanksz"
            extra="mb-3"
            value={editExplanation}
            placeholder="Enter Explanation"
            type="text"
            id="feedbackInput"
            rows="5"
            className="mt-2 flex w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
          />
          <div className="my-2 flex items-center">
<Uploader labelName="Explanation" setUploadedLink={setQuestionExplanationEditImageURL} /> {questionExplanationEditImageURL && questionExplanationEditImageURL !== null && questionExplanationEditImageURL !== "" && <img width="55px" className="mx-2 mt-2" src={questionExplanationEditImageURL} />}
        </div>
        </div>
        {/* <InputField
        onChange={(e) => settestduration(e.target.value)}
          variant="qbank"
          extra="mb-3"
          label="Duration*"
          placeholder="Enter Test Duration"
          id="type"
          type="text"
        /> */}

      </Modal>



      <Modal
        open={openAddOptionModal}
        title="Add Option"
        onOk={handleOptionAdd}
        onCancel={handleAddOptionCancel}
        footer={[
          <Button key="back" onClick={handleAddOptionCancel}>
            Cancel
          </Button>,
          <Button key="submit" style={{ backgroundColor: "#1677ff" }} type="primary" loading={loading} onClick={handleOptionAdd}>
            Add
          </Button>,

        ]}
      >
        <InputField
          onChange={(e) => setOptionName(e.target.value)}
          variant="qbank"
          extra="mb-3"
          value={optionName}
          label="Option Name*"
          placeholder="Enter Option Name"
          id="name"
          type="text"
        />

<div className="my-2 flex items-center">

<Uploader setUploadedLink={setOptionCreateImageURL} /> {optionCreateImageURL && optionCreateImageURL !== null && optionCreateImageURL !== "" && <img width="55px" className="mx-2 mt-2" src={optionCreateImageURL} />}
        </div>

        <div>
          <label
            htmlFor="feedbackInput"
            className="text-sm text-navy-700 dark:text-white ml-3 font-bold"
          >
            IsCorrect*
          </label>
          <Checkbox
            id="feedbackInput"
            className='mt-3 ml-5 text-lg'
            checked={optionCorrect}
            onChange={() => setOptionCorrect(!optionCorrect)}
          >
            {optionCorrect ? 'Correct' : 'Incorrect'}
          </Checkbox>
        </div>


      </Modal>

      {/* Edit Option Modal */}
      <Modal
        open={openEditOptionModal}
        title="Edit Option"
        onOk={handleOptionEdit}
        onCancel={handleEditOptionCancel}
        footer={[
          <Button key="back" onClick={handleEditOptionCancel}>
            Cancel
          </Button>,
          <Button key="submit" style={{ backgroundColor: "#1677ff" }} type="primary" loading={loading} onClick={handleOptionEdit}>
            Edit
          </Button>,

        ]}
      >
        <InputField
          onChange={(e) => setEditOptionName(e.target.value)}
          variant="qbank"
          extra="mb-3"
          value={editOptionName}
          label="Option Name*"
          placeholder="Enter Option Name"
          id="name"
          type="text"
        />

<div className="my-2 flex items-center">

<Uploader setUploadedLink={setOptionEditImageURL} /> {optionEditImageURL && optionEditImageURL !== null && optionEditImageURL !== "" && <img width="55px" className="mx-2 mt-2" src={optionEditImageURL} />}
        </div>

        <div>
          <label
            htmlFor="feedbackInput"
            className="text-sm text-navy-700 dark:text-white ml-3 font-bold"
          >
            IsCorrect*
          </label>
          <Checkbox
            id="feedbackInput"
            className='mt-3 ml-5 text-lg'
            checked={editOptionCorrect}
            onChange={() => setEditOptionCorrect(!editOptionCorrect)}
          >
            {editOptionCorrect ? 'Correct' : 'Incorrect'}
          </Checkbox>
        </div>


      </Modal>


      <Modal
        open={openBulkModal}
        title="Bulk Create Test"
        onOk={handleModalCreate}
        onCancel={handleBulkCancel}
        footer={[
          <Button key="back" onClick={handleBulkCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            style={{ backgroundColor: '#1677ff' }}
            type="primary"
            loading={loading}
            onClick={handleModalCreate}
          >
            Submit
          </Button>
        ]}
      >

        <InputField
          onChange={(e) => setbulktestname(e.target.value)}
          variant="qbank"
          extra="mb-3"
          label="Name*"
          placeholder="Enter Test Name"
          id="name"
          type="text"
        />
        <InputField
          onChange={(e) => setbulktestdesc(e.target.value)}
          variant="qbank*"
          extra="mb-3"
          label="Description*"
          placeholder="Enter Test Description"
          id="desc"
          type="text"
        />

        <CSVReader onFileLoaded={handleFileUpload} />
      </Modal>


      {/* <Modal
        open={openBulkModal}
        title="Bulk Create Test"
        onOk={handleModalCreate}
        onCancel={handleBulkCancel}
        footer={[
          <Button key="back" onClick={handleBulkCancel}>
            Cancel
          </Button>,
          <Button key="submit" style={{backgroundColor:"#1677ff"}} type="primary" loading={loading} onClick={handleModalCreate}>
            Submit
          </Button>,
          
        ]}
      >
  
    <Upload
      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      listType="CSV"
      maxCount={1}
    >
      <Button icon={<UploadOutlined />}>Upload CSV </Button>
    </Upload>
      </Modal> */}

      <Modal
        open={open}
        title="Create QBank"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" style={{ backgroundColor: "#1677ff" }} type="primary" loading={loading} onClick={handleOk}>
            Submit
          </Button>,

        ]}
      >
        <InputField
          onChange={(e) => setqbankname(e.target.value)}
          variant="qbank"
          extra="mb-3"
          label="Name*"
          placeholder="Enter QBank Name"
          id="name"
          type="text"
        />
        <InputField
          onChange={(e) => setqbankdesc(e.target.value)}
          variant="qbank*"
          extra="mb-3"
          label="Description*"
          placeholder="Enter QBank Description"
          id="desc"
          type="text"
        />
        <InputField
          onChange={(e) => setqbanktype(e.target.value)}
          variant="qbank"
          extra="mb-3"
          label="Type*"
          placeholder="Enter QBank Type"
          id="type"
          type="text"
        />

      </Modal>




      {isLoader ? (
        <div className="flex items-center justify-center h-full">
          <Spin />
        </div>
      ) : (

        <div className="mt-8 h-full overflow-x-scroll xl:overflow-hidden">
          <table {...getTableProps()} className="w-full">
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {/* <th
                  
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                > */}
                  {/* <p className="text-xs tracking-wide text-gray-600">
                    ID
                  </p> */}
                  {/* </th> */}
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      className="border-b border-gray-200 pb-[10px] text-start dark:!border-navy-700"
                    >
                      <p className="text-xs tracking-wide text-gray-600">
                        {column.render("Header")}
                      </p>
                    </th>
                  ))}


                  {/* <th
                  
                    className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                  > */}
                  {/* <p className="text-xs tracking-wide text-gray-600">
                      ACTIONS
                    </p> */}
                  {/* </th> */}
                </tr>
              ))}
            </thead>
            {rows && rows.length !== 0 ?
              <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {

                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={index}>


                      {row.cells.map((cell, index) => {
                        let data = "";

                        if (cell.column.Header === "ID") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {row?.index + 1}
                            </p>
                          );
                        }
                        else if (cell.column.Header === "NAME") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value !== null ? cell.value : "Name not mentioned"}
                            </p>
                          );
                        } else if (cell.column.Header === "TYPE") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {cell.value !== null ? cell.value : "Type not mentioned"}
                            </p>
                          );
                        } else if (cell.column.Header === "DESCRIPTION") {
                          const description = cell.value !== null ? cell.value : "Description not mentioned";
                          const truncatedDescription = description.length > 20 ? description.slice(0, 20) + "..." : description;

                          return (
                            <td
                              className="pt-[14px] pb-[18px] sm:text-[14px]"
                              {...cell.getCellProps()}
                              key={index}
                            >
                              <Tooltip title={description}>
                                <p className="text-sm font-bold text-navy-700 dark:text-white">
                                  {truncatedDescription}
                                </p>
                              </Tooltip>
                            </td>
                          );
                        }
                        else if (cell.column.Header === "TESTS") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {/* {cell.value !== null ? cell.value : "Country not mentioned" } */}


                              {
                                cell.value && (cell.value.length !== 0) ?
                                  cell.value.map((e, i) => {
                                    return (

                                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                                        {i + 1}. {e?.name !== null ? e?.name : "Test not mentioned"}
                                      </p>
                                    )
                                  })
                                  :
                                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                                    No Test
                                  </p>
                              }


                            </p>
                          );
                        } else if (cell.column.Header === "STATUS") {
                          data = (


                            <div className="flex items-center gap-2">
                              <div className={`rounded-full text-xl`}>

                                {row?.original?.isactive === true ? (

                                  <MdCheckCircle className="text-green-500" />
                                ) :
                                  row?.original?.isactive === false ? (
                                    <MdCancel className="text-red-500" />
                                  ) : cell.value === "Error" ? (
                                    <MdOutlineError className="text-orange-500" />
                                  ) : null}
                              </div>
                              <p className="text-sm font-bold text-navy-700 dark:text-white">
                                {row?.original?.isactive === false ? (
                                  "Blocked"
                                ) :
                                  row?.original?.isactive === true ? (
                                    "Unblocked"
                                  ) : null}                          </p>
                            </div>
                          );
                        } else if (cell.column.Header === "ACTIONS") {
                          data = (
                            <p className="text-sm font-bold text-navy-700 dark:text-white flex">




                              <Tooltip title="Block QBank">

                                <button
                                  onClick={() => BlockUser(row?.original?.id)}
                                  className={`flex items-center text-xl hover:cursor-pointer mr-5 ${false
                                    ? "bg-none text-white hover:bg-none active:bg-none"
                                    : "bg-red-500 p-2 text-white hover:bg-red-400 dark:bg-red-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                    } linear justify-center rounded-lg font-bold transition duration-200`}
                                >
                                  <CgBlock className="h-6 w-6" />
                                </button>

                              </Tooltip>


                              <Tooltip title="UnBlock QBank">

                                <button
                                  onClick={() => UnBlockUser(row?.original?.id)}
                                  className={`flex items-center text-xl hover:cursor-pointer ${false
                                    ? "bg-none text-white hover:bg-none active:bg-none"
                                    : "bg-blue-500 p-2 text-white hover:bg-blue-400 dark:bg-blue-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                    } linear justify-center rounded-lg font-bold transition duration-200`}
                                >
                                  <CgUnblock className="h-6 w-6" />
                                </button>
                              </Tooltip>




                              <Tooltip title="More Details">



                                <button
                                  onClick={() => showDrawer(row?.original)}
                                  className={`flex items-center text-xl mx-5 hover:cursor-pointer ${false
                                    ? "bg-none text-white hover:bg-none active:bg-none"
                                    : "bg-green-500 p-2 text-white hover:bg-green-400 dark:bg-green-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                    } linear justify-center rounded-lg font-bold transition duration-200`}
                                >
                                  <AiOutlineInfoCircle className="h-6 w-6" />
                                </button>

                              </Tooltip>



                            </p>
                          );
                        }
                        else if (cell.column.Header === "PROGRESS") {
                          data = <Progress width="w-[68px]" value={cell.value} />;
                        }


                        return (
                          <td
                            className="pt-[14px] pb-[18px] sm:text-[14px]"
                            {...cell.getCellProps()}
                            key={index}
                          >
                            <div
                              // onClick={() => handleCellClick(index, index)}
                            >

                              {data}


                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
              :
              <tr>
                <td colSpan={5}>
                  <br />
                  <br />
                  <Empty />
                  <br />
                  <br />

                </td>
              </tr>
            }

          </table>
        </div>

      )}
    </Card>
  );
};

export default ComplexTable;
