import { Modal, Button, Space, Checkbox, notification, Upload, message  } from 'antd';
import { useState } from 'react';
import InputField from "components/fields/InputField";
import axios from "axios";
import { BASE_URL } from "variables/Endpoints";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from '@ant-design/icons';

const OptionFormModal = ({ openQuestionModal,setopenQuestionModal, handleQuestionCreate,testID, fetchData, setOpenDrawer }) => {
  const [testname, settestname] = useState('');
  const [testdesc, settestdesc] = useState('');
  const [numFields, setNumFields] = useState(1);
  const [options, setOptions] = useState([{ name: "", optionN: false, image: "" }]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [uploadedLink, setUploadedLink] = useState(null);
  const [uploadedExplanationLink, setUploadedExplanationLink] = useState(null);


  let tokenMe = localStorage.getItem("accessToken");
  const increaseFields = () => {
    setNumFields(numFields + 1);
    setOptions([...options, {name:"", optionN:false}]);
  };
  const openNotificationsucess = (placement,msg) => {
    api.success({
      message: msg,
      placement,
    });
  };
  
  const openNotificationError = (placement,msg) => {
    api.error({
      message: msg,
      placement,
    });
  };
  const decreaseFields = () => {
    if (numFields > 1) {
      setNumFields(numFields - 1);
      setOptions(options.slice(0, options.length - 1));
    }
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index].name = value;
    setOptions(updatedOptions);
  };
  
  const handleImageChange = (index, file) => {
    const updatedOptions = [...options];
    updatedOptions[index].image = file;
    setOptions(updatedOptions);
  };
  

  const handleCheckboxChange = (index) => {
    const updatedOptions = [...options];
    console.log(updatedOptions, index);
    updatedOptions[index].optionN = !updatedOptions[index]?.optionN;
    setOptions(updatedOptions);
  };


  // const handleCheckboxChange = (index) => {
  //   const updatedOptions = options.map((option, i) => {
  //     if (i === index) {
  //       return {
  //         ...option,
  //         isTrue: !option.isTrue
  //       };
  //     }
  //     return option;
  //   });
  //   setOptions(updatedOptions);
  // };
  const handleImageUpload = async (index, file, onSuccess, onError) => {
    try {
      const formData = new FormData();
      formData.append('images', file);
  
      // Axios POST request
      const response = await axios.post('https://backend.nextgentestprep.com/api/admin/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          'Authorization': `Bearer ${tokenMe && tokenMe}`
        }
      });
  
      if (response.status === 200) {
        const data = response.data;
        const hostedLink = data?.imageUrls;
        handleImageChange(index, hostedLink);
        onSuccess();
        message.success('Upload successful');
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      onError(error);
      message.error('Upload failed');
      if ((error === "jwt expired") || (error === "jwt malformed")) {
        localStorage.clear();
        navigate("/auth/sign-in");
      } else {
        // Handle other errors here
        openNotificationError('top', error.message);
      }
    }
  };
  

  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append('images', file);


      // Axios POST request
      const response = await axios.post('https://backend.nextgentestprep.com/api/admin/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          'Authorization': `Bearer ${tokenMe && tokenMe}`
        }
      });

      if (response.status === 200) {
        const data = response.data;
        const hostedLink = data?.imageUrls; 
        setUploadedLink(hostedLink);
        onSuccess();
        message.success('Upload successful');
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      onError(error);
      message.error('Upload failed');
      if((error === "jwt expired") || (error === "jwt malformed")){        // Handle token expiration or unauthorized access here
        localStorage.clear();
        navigate("/auth/sign-in");
      } else {
        // Handle other errors here
        openNotificationError('top', error.message);
      }
    }
  };

  const customExplanationRequest = async ({ file, onSuccess, onError }) => {
    try {
      const formData = new FormData();
      formData.append('images', file);


      // Axios POST request
      const response = await axios.post('https://backend.nextgentestprep.com/api/admin/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Use multipart/form-data for file uploads
          'Authorization': `Bearer ${tokenMe && tokenMe}`
        }
      });

      if (response.status === 200) {
        const data = response.data;
        const hostedLink = data?.imageUrls; 
        setUploadedExplanationLink(hostedLink);
        onSuccess();
        message.success('Upload successful');
      } else {
        throw new Error('Upload failed');
      }
    } catch (error) {
      onError(error);
      message.error('Upload failed');
      if((error === "jwt expired") || (error === "jwt malformed")){        // Handle token expiration or unauthorized access here
        localStorage.clear();
        navigate("/auth/sign-in");
      } else {
        // Handle other errors here
        openNotificationError('top', error.message);
      }
    }
  };



  const onCancel = () => {
    settestname('');
    settestdesc('');
    setNumFields(1);
    setOptions([{ name: "", optionN: false, image: "" }]);
    setopenQuestionModal(false);
  };

  const onSubmit = () => {
    const requestBody = {
      testid: testID,
      description: testname,
      explanation: testdesc,
      image:uploadedLink,
      explanation_img:uploadedExplanationLink,
      options: options.map((option, index) => ({
        name: option?.name,
        isTrue: option?.optionN || false,
      image: option?.image || null, 
      }))
    };
      fetch('https://backend.nextgentestprep.com/api/admin/question', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${tokenMe && tokenMe}`
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => response.json())
      .then(data => { 
        openNotificationsucess('top',data?.data);
        setopenQuestionModal(false);
        fetchData();
        setOpenDrawer(false);
      })
      .catch(error => {
        console.error('API error:', error);
        if((error === "jwt expired") || (error === "jwt malformed")){
          localStorage.clear();
          navigate("/auth/sign-in")
        }else{
      
          openNotificationError('top',error);
      
        }
        setopenQuestionModal(false);

      });
  };

 

  

  return (
    <Modal
      visible={openQuestionModal}
      title="Create Question"
      onOk={handleQuestionCreate}
      onCancel={onCancel}
      footer={[
          
        <Button key="back" className='mx-2' onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" className='ml-2' type="primary" style={{ backgroundColor: '#1677ff' }} onClick={onSubmit}>
          Create
        </Button>,
      ]}
    >
      {contextHolder}
      <br />
      <InputField
        onChange={(e) => settestname(e.target.value)}
        variant="qbank"
        extra="mb-3"
        label="Name*"
        placeholder="Enter Question"
        id="name"
        type="text"
      />
<div className="my-2 mb-4 flex items-center">

<label
        htmlFor="image"
        className="text-sm text-navy-700 dark:text-white ml-3 font-bold mr-6"
        
      >
        Image 
      </label>
  
<Upload
        customRequest={customRequest}
        id="image"
        listType="picture"
        maxCount={1}
        showUploadList={true}
        fileList={[]}

      >
        <Button className='flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none' icon={<UploadOutlined className="mb-1" />}>Upload (Max: 1)</Button>
      </Upload>

   {uploadedLink && uploadedLink !== null && uploadedLink !== "" && <img width="55px" className="mx-2 border" src={uploadedLink} />}

        
        </div>
      <InputField 
        onChange={(e) => settestdesc(e.target.value)}
        variant="qbank*"
        extra="mb-3"
        label="Explanation*"
        placeholder="Enter Question Explanation"
        id="desc"
        type="text"
      />
<div className="my-2 flex items-center">

<label
        htmlFor="image"
        className="text-sm text-navy-700 dark:text-white ml-3 font-bold mr-6"
        
      >
       Explanation Image 
      </label>
  
<Upload
        customRequest={customExplanationRequest}
        id="image"
        listType="picture"
        maxCount={1}
        showUploadList={true}
        fileList={[]}

      >
        <Button className='flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none' icon={<UploadOutlined className="mb-1" />}>Upload (Max: 1)</Button>
      </Upload>

   {uploadedExplanationLink && uploadedExplanationLink !== null && uploadedExplanationLink !== "" && <img width="55px" className="mx-2 border" src={uploadedExplanationLink} />}

        
        </div>


<div className='flex justify-end mt-8 mb-2'>


<Button key="add" className='mx-2' onClick={increaseFields}>
            Add Option
          </Button>
          <Button key="remove" className='ml-2' type="primary" style={{ backgroundColor: '#1677ff' }} onClick={decreaseFields}>
            Remove Option
          </Button>
          </div>

          {Array.from({ length: numFields }).map((_, index) => (
<>
<div className="flex items-center">

<InputField
      onChange={(e) => handleOptionChange(index, e.target.value)}
      variant="qbank*"
      extra="mb-3"
      label={`Option ${index + 1}`}
      placeholder="Enter Option"
      id={`option-${index}`}
      type="text"
    />
    <Checkbox
      className="mt-3 ml-5 text-lg"
      checked={options[index]?.optionN}
      onChange={() => handleCheckboxChange(index)}
      >
      {options[index]?.optionN ? 'Correct' : 'Incorrect'}
    </Checkbox>
      </div>
    
    <div className="my-2 flex items-center">

<label
        htmlFor={`image-option-${index}`}
        className="text-sm text-navy-700 dark:text-white ml-3 font-bold mr-6"
        
      >
        Image 
      </label>
    <Upload
      customRequest={({ file, onSuccess, onError }) =>
        handleImageUpload(index, file, onSuccess, onError)
      }
      id={`image-option-${index}`}
      listType="picture"
      maxCount={1}
      showUploadList={true}
      fileList={[]}
    >
      <Button
        className="flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
        icon={<UploadOutlined className="mb-1" />}
      >
        Upload (Max: 1)
      </Button>
    </Upload>
    {options[index].image && options[index].image !== null && options[index].image !== "" && <img width="55px" className="mx-2 border" src={options[index].image} />}

    </div>
      </>
))}

    </Modal>
  );
};

export default OptionFormModal;
