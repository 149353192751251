export const columnsDataDevelopment = [
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "ID",
    accessor: "id",
  },
  
  {
    Header: "FIRST NAME",
    accessor: "firstname",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  
  {
    Header: "QBANK NAME",
    accessor: "qbank",
  },
  {
    Header: "TEST NAME",
    accessor: "test",
  },
  {
    Header: "QUESTION",
    accessor: "question",
  },
  {
    Header: "DESCRIPTION",
    accessor: "description",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },{
    Header: "RESPONSE",
    accessor: "response",
  },
  {
    Header:"ACTIONS",
    accessor: "actions",
  }
];
