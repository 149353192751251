
import {
  columnsDataComplex,
} from "./variables/columnsData";

import ComplexTable from "./components/ComplexTable";


const UserTable = () => {

 

  return (
    <div className="h-full wmt-10">
             <ComplexTable
          columnsData={columnsDataComplex}
        />
    </div>
  );
};

export default UserTable;
